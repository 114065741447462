<template>
    <admin-dashboard-layout :loading="loading">
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col v-text="user.name"/>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.users.index'}" v-text="'return'"/>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field outlined dense label="Name"  v-model="user.name"/>
                        </v-col>
                        <v-col>
                            <v-text-field outlined dense label="Email"  v-model="user.email"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field :type="id ? 'password' : 'text'" v-model="user.password" outlined dense label="Password"/>
                        </v-col>
                        <v-col>
                            <v-text-field :type="id ? 'password' : 'text'" v-model="user.password_confirmation" outlined dense label="Confirm password"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="user.industry_id"
                                outlined dense
                                label="Industries"
                                item-text="name"
                                item-value="id"
                                :items="industries"/>
                        </v-col>
                    </v-row>
                    <v-row v-if="$route.params.id">
                        <v-col>
                            <v-text-field label="сreated at" v-model="user.created_at" disabled/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-center">
                            <v-btn v-if="$route.params.id" @click="handleDelete" color="red" v-text="'Delete'"/>
                        </v-col>
                        <v-col class="text-center">
                            <v-btn @click="handleSave" v-text="'Save'"/>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
    import User from "@/models/User";
    import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
    import Industry from "@/models/Industry";
    import {mapMutations} from "vuex";

    export default {
        name: "users-show",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                id: null,
                user: {},
                loading: true,
                industries: []
            }
        },
        async mounted() {
            this.id = this.$route.params.id;

            if (this.id) {
                this.user = await User.find(this.id);
            } else {
                let password = this.generatePassword();

                this.user.password = password;
                this.user.password_confirmation = password;
            }

            await this.getIndustries();

            this.loading = false;
        },
        methods: {
            generatePassword() {
                return Math.random().toString(36).slice(2);
            },

            async handleSave() {
                const user = new User(this.user)
                this.user = await user.save()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})

                if (this.user.id && !this.$route.params.id) {
                    await this.$router.push({name: 'admin.users.edit', params: {id: this.user.id}})
                }
            },

            async handleDelete() {
                let user = await User.find(this.$route.params.id)
                user.delete()
                this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})

                this.$router.push({name: 'admin.users.index'})
            },

            async getIndustries() {
                this.industries = await Industry.get();

                if (!this.user.industry_id) {
                    this.user.industry_id = this.industries[0].id
                }
            },

            ...mapMutations(['showSnackBar']),
        }
    }
</script>

<style scoped>

</style>
